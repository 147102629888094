import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  ReferenceField,
  useTheme,
  usePermissions,
} from "react-admin";
import GoodsOutAddDialog from "../../components/goodsOuts/create/GoodsOutAddDialog";
import GoodsOutEditDialog from "../../components/goodsOuts/edit/GoodsOutEditDialog";
import goodsOutFilterList from "../../components/goodsOuts/filter/goodsOutFilterList";
// import EditBtn from "../../components/editBtn/EditBtn";
import DeliveryNote from "../../components/print/DeliveryNote";
import GoodsOutExpandList from "../../components/goodsOuts/expand/GoodsOutExpandList";
import CompleteWithCustomExportActions from "../../components/tableListActions/CompleteWithCustomExportActions";

const GoodsOutList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  // const handleClickOpenEdit = (recId) => () => {
  //   setRecordId(recId);
  //   setOpenEdit(true);
  // };
  const list = useMemo(
    () => (
      <List
        // resource="transactions"
        // storeKey="goodsOut"
        title="Barang Keluar"
        empty={false}
        actions={
          <CompleteWithCustomExportActions createHandler={handleClickOpenAdd} />
        }
        filters={goodsOutFilterList(permissions)}
        filter={{ type: "out" }}
        sort={{ field: "created_at", order: "DESC" }}
        // filterDefaultValues={{ type: "out" }}
        // queryOptions={{ meta: { sort: ["transactions.created_at DESC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable
          expand={<GoodsOutExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme === "light" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField source="company.alias" label="Depo" />
          ) : null}
          <TextField
            source="delivery_note.code"
            sortBy="delivery_notes.code"
            label="Delivery Order"
          />
          <ReferenceField
            label="mine.tbl_header.customer"
            source="customer_id"
            reference="customers/some"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="send_date" label="mine.tbl_header.send_date" />
          <TextField source="note" label="mine.label.note" emptyText="-" />
          <DateField
            source="created_at"
            showTime
            label="mine.tbl_header.created_at"
          />
          <ReferenceField
            label="mine.tbl_header.created_by"
            source="created_by"
            reference="users/some"
          >
            <TextField source="username" />
          </ReferenceField>
          <WrapperField label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return (
                  <>
                    {/* <EditBtn editHandler={handleClickOpenEdit(record.id)} /> */}
                    <DeliveryNote record={record} />
                  </>
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      {openAdd ? (
        <GoodsOutAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <GoodsOutEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default GoodsOutList;
