import { DateInput, SelectInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const activityFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="company"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="user"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="action_url"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <SelectInput
    source="req_method"
    resettable
    choices={[
      { id: "GET", name: "GET" },
      { id: "POST", name: "POST" },
      { id: "PUT", name: "PUT" },
      { id: "DELETE", name: "DELETE" },
    ]}
    variant="outlined"
  />,
  <TextInput
    source="note"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="ip"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    source="created_at"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
];

export default activityFilterList;
