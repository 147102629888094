import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const GoodsInExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        boxShadow: "0 2px 4px rgba(0,0,0,.4)",
        borderRadius: "4px",
        bgcolor: "background.default", // "#fff",
      }}
    >
      <TabbedShowLayout.Tab label="product">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            // borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.label.name")}</th>
              <th>{t("mine.label.color")}</th>
              <th>{t("mine.label.size")}</th>
              <th style={{ textAlign: "right" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {record.product_ins.map((item, idx) => {
              return (
                <tr key={item.id}>
                  <td>{item.product.name}</td>
                  <td>{item.product.color ? item.product.color : "-"}</td>
                  <td>{item.product.size ? item.product.size : "-"}</td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="mutation"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  );
};

export default GoodsInExpandList;
