import {
  BooleanInput,
  DateInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const userFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="first_name"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="last_name"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <SelectInput
    source="gender"
    choices={[
      { id: "M", name: "Male" },
      { id: "F", name: "Female" },
    ]}
    variant="outlined"
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="end">
    //       <FilterAltIcon />
    //     </InputAdornment>
    //   ),
    // }}
  />,
  <TextInput
    source="username"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="email"
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <NumberInput
    source="phone"
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <BooleanInput
    source="active"
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
  <DateInput
    source="created_at"
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <FilterAltIcon />
        </InputAdornment>
      ),
    }}
  />,
];

export default userFilterList;
