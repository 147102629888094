import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  NumberField,
  FunctionField,
  usePermissions,
  DateField,
  useTranslate,
} from "react-admin";
import CorrectionAddDialog from "../../components/corrections/create/CorrectionAddDialog";
import CorrectionEditDialog from "../../components/corrections/edit/CorrectionEditDialog";
import summaryFilterList, {
  administratorSummaryFilterList,
} from "../../components/summaries/filter/summaryFilterList";
// import SummaryExpandList from "../../components/summaries/expand/SummaryExpandList";
import ColumnExportActions from "../../components/tableListActions/ColumnExportActions";

const SummaryList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const t = useTranslate();

  const list = useMemo(
    () => (
      <List
        // exporter={exporter}
        title={t("resources.stock_mutation.name")}
        empty={false}
        actions={<ColumnExportActions />}
        filters={
          permissions && !permissions.includes("admin_depo")
            ? administratorSummaryFilterList
            : summaryFilterList
        }
        sort={{ field: "created_at", order: "DESC" }}
        // filterDefaultValues={{ type: "adj" }}
        // queryOptions={{ meta: { sort: ["created_at ASC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          // expand={<SummaryExpandList />}
          // sx={{
          //   "& .RaDatagrid-expandedPanel > td": {
          //     p: 0,
          //   },
          // }}
        >
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField source="company.alias" label="Depo" component="p" />
          ) : null}
          <FunctionField
            source="products.name" // atau pake sortBy
            label="mine.label.name"
            component="p"
            render={(record) => {
              if (record.product.name) {
                return record.product.name;
              } else {
                return "-";
              }
            }}
          />
          <FunctionField
            source="products.color" // atau pake sortBy
            label="mine.label.color"
            component="p"
            render={(record) => {
              if (record.product.color) {
                return record.product.color;
              } else {
                return "-";
              }
            }}
          />
          <FunctionField
            source="products.size" // atau pake sortBy
            label="mine.label.size"
            component="p"
            render={(record) => {
              if (record.product.size) {
                return record.product.size;
              } else {
                return "-";
              }
            }}
          />
          <FunctionField
            label="mine.label.code"
            component="p"
            render={(record) => {
              if (record.transaction.delivery_note.code) {
                return record.transaction.delivery_note.code;
              } else if (record.transaction.correction_in.code) {
                return record.transaction.correction_in.code;
              } else if (record.transaction.correction_out.code) {
                return record.transaction.correction_out.code;
              } else if (record.transaction.purchase_order.code) {
                return record.transaction.purchase_order.code;
              } else {
                return "-";
              }
            }}
          />
          <NumberField source="previous" label="mine.label.previous_stock" />
          <FunctionField
            source="summaries.in" // atau pake sortBy
            label="resources.in.name"
            component="p"
            textAlign="right"
            render={(record) => {
              if (record.in) {
                return record.in;
              } else {
                return "-";
              }
            }}
          />
          <FunctionField
            source="summaries.out" // atau pake sortBy
            label="resources.out.name"
            component="p"
            textAlign="right"
            render={(record) => {
              if (record.out) {
                return record.out;
              } else {
                return "-";
              }
            }}
          />
          <NumberField source="correction" label="mine.label.correction" />
          <NumberField source="total" label="mine.label.final_amount" />
          <DateField
            showTime
            source="created_at"
            label="mine.tbl_header.created_at"
          />
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <CorrectionAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <CorrectionEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default SummaryList;
