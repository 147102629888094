import { memo } from "react";
import Grid from "@mui/material/Grid";
import CityInput from "../../common/CityInput";
import LocationNameInput from "../../common/LocationNameInput";
import ProvinceInput from "../../common/ProvinceInput";
import DetailInput from "../../common/DetailInput";
import PostalCodeInput from "../../common/PostalCodeInput";
import PhoneInput from "../../common/PhoneInput";
import FaxInput from "../../common/FaxInput";
import { maxLength, number, required } from "react-admin";

const validateLocationName = [required(), maxLength(100)];
const validateProvince = [required(), number()];
const validatePostalCode = [number()];
const validateDetail = [required()];
const validatePhone = [required(), maxLength(20)];
const validateFax = [maxLength(20)];

const Monyet = ({
  city_id,
  detail,
  fax,
  location_name,
  phone,
  postal_code,
  province_id,
  // NAMA-NAMA SOURCE
  cityIdSource,
  detailSource,
  faxSource,
  locationNameSource,
  phoneSource,
  postalCodeSource,
  provinceIdSource,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12}>
        <LocationNameInput
          variant="outlined"
          source={locationNameSource}
          fullWidth
          isRequired
          validate={validateLocationName}
          locationName={location_name}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ProvinceInput
          variant="outlined"
          source={provinceIdSource}
          isRequired
          fullWidth
          validate={validateProvince}
          provinceId={province_id}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CityInput
          variant="outlined"
          source={cityIdSource}
          isRequired
          fullWidth
          validate={validateProvince}
          provinceId={province_id}
        />
      </Grid>
      <Grid item xs={12}>
        <DetailInput
          variant="outlined"
          source={detailSource}
          fullWidth
          isRequired
          multiline
          minRows={2}
          maxRows={2}
          validate={validateDetail}
          detail={detail}
        />
      </Grid>
      <Grid item xs={12}>
        <PostalCodeInput
          variant="outlined"
          source={postalCodeSource}
          fullWidth
          validate={validatePostalCode}
          postalCode={postal_code}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneInput
          variant="outlined"
          source={phoneSource}
          fullWidth
          isRequired
          validate={validatePhone}
          phone={phone}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FaxInput
          variant="outlined"
          source={faxSource}
          fullWidth
          validate={validateFax}
          fax={fax}
        />
      </Grid>
    </Grid>
  );
};

const propsAreEqual = (prevProps, nextProps) => {
  if (
    prevProps.city_id === nextProps.city_id &&
    prevProps.detail === nextProps.detail &&
    prevProps.fax === nextProps.fax &&
    prevProps.location_name === nextProps.location_name &&
    prevProps.phone === nextProps.phone &&
    prevProps.postal_code === nextProps.postal_code &&
    prevProps.province_id === nextProps.province_id
  ) {
    // tidak perlu render ulang
    return true;
  }
  // perlu dirender ulang
  return false;
};
const Beruk = memo(Monyet, propsAreEqual);

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  return (
    <Beruk
      {...scopedFormData}
      cityIdSource={getSource("city_id")}
      detailSource={getSource("detail")}
      faxSource={getSource("fax")}
      locationNameSource={getSource("location_name")}
      phoneSource={getSource("phone")}
      postalCodeSource={getSource("postal_code")}
      provinceIdSource={getSource("province_id")}
    />
  );
};
export default Inputs;
