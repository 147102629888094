import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  FunctionField,
  WithRecord,
  usePermissions,
} from "react-admin";
import CompanyAddDialog from "../../components/companies/create/CompanyAddDialog";
import CompanyEditDialog from "../../components/companies/edit/CompanyEditDialog";
import companyFilterList from "../../components/companies/filter/companyFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const CompanyList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(() => {
    return !permissions ? null : (
      <List
        empty={false}
        actions={
          <TableListActions
            createHandler={
              permissions.includes("administrator")
                ? handleClickOpenAdd
                : undefined
            }
          />
        }
        filters={companyFilterList}
        sort={{ field: "created_at", order: "ASC" }}
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable>
          <FunctionField
            label="mine.label.name"
            render={(record) => `${record.alias}, ${record.name}`}
          />
          <FunctionField
            label="mine.label.address"
            render={(record) =>
              `${record.detail}, ${record.city.type} ${record.city.name}, ${record.province.name}, ${record.postal_code}`
            }
          />
          <TextField source="phone" />
          <TextField source="fax" />
          <FunctionField
            label="Bank"
            render={(record) =>
              `${record.bank}, ${record.bank_acc_name}, ${record.bank_acc_number}`
            }
          />
          <WrapperField label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    );
  }, [permissions]);
  return (
    <>
      {list}
      {openAdd ? (
        <CompanyAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <CompanyEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default CompanyList;
