import englishMessages from "ra-language-english";
const en = {
  ra: {
    ...englishMessages.ra,
    action: {
      ...englishMessages.ra.action,
    },
  },
  resources: {
    company: {
      name: "Company |||| Companies",
    },
    user: {
      name: "User |||| Users",
    },
    chart_of_account: {
      name: "Chart of Account |||| Chart of Accounts",
    },
    sales_area: {
      name: "Sales Area |||| Sales Areas",
    },
    customer_type: {
      name: "Customer Type |||| Customer Types",
    },
    supplier: {
      name: "Supplier |||| Suppliers",
    },
    brand: {
      name: "Brand |||| Brands",
    },
    size: {
      name: "Size |||| Sizes",
    },
    kind: {
      name: "Kind |||| Kinds",
    },
    density: {
      name: "Density |||| Densities",
    },
    color: {
      name: "Color |||| Colors",
    },
    quality: {
      name: "Quality |||| Qualities",
    },
    product: {
      name: "Product |||| Products",
    },
    warehouse: {
      name: "Warehouse |||| Warehouses",
    },
    transport: {
      name: "Transport |||| Transports",
    },
    order: {
      name: "Order |||| Orders",
      fields: {
        code: "%{name}",
        date: "%{name}",
        color: "Color",
      },
    },
    sale: {
      name: "Sale |||| Sales",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    purchase: {
      name: "Purchase |||| Purchases",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    return: {
      name: "Return |||| Returns",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    customer: {
      name: "Customer |||| Customers",
      fields: {
        first_name: "First name",
        last_name: "Last name",
        dob: "Date of birth",
      },
    },
    price_limit: {
      name: "Price limit |||| Price limits",
      fields: {
        customer_type: "Customer type",
        price: "Price limit",
        note: "Note",
      },
    },
    brand_price_addition: {
      name: "Brand Price Additions",
    },
    size_price_addition: {
      name: "Size Price Additions",
    },
    debt_payment: {
      name: "Pay",
    },
    debt_correction: {
      name: "Correction",
    },
    check_cashing: {
      name: "Check Cashing",
    },
    journal: {
      name: "Journal |||| Journals",
    },
    correction: {
      name: "Correction |||| Corrections",
    },
    mutation: {
      name: "Mutation |||| Mutations",
    },
    stock_mutation: {
      name: "Stock Mutation |||| Stock Mutations",
    },
    in: {
      name: "In",
    },
    out: {
      name: "Out",
    },
    bad_debt: {
      name: "Bad Debt",
    },
    bad_debt_installment: {
      name: "Installment",
    },
    bad_debt_correction: {
      name: "Correction",
    },
    bad_debt_check_cashing: {
      name: "Check Cashing",
    },
    mutation_repacking: {
      name: "Repacking",
    },
    mutation_inventory_write_off: {
      name: "Inventory Write Off",
    },
    mutation_change_goods_code: {
      name: "Change Goods Code",
    },
    mutation_change_location: {
      name: "Change Location",
    },
    all_transaction_journals: {
      name: "All Transaction Journals",
    },
    activity: {
      name: "Activity |||| Activities",
    },
  },
  mine: {
    filter: {
      search_all: "Search all",
    },
    expand_menu: {
      purchase: "Purchases",
      sale: "Sales",
      item: "Items",
      factory_debt: "factory debt",
      customer_debt: "customer debt",
      bad_debt: "Bad Debts",
      mutation: "Mutations",
      report: "Report",
      general_ledger: "General Ledger",
    },
    message: {
      approve: "Approve",
      approve_content: "Are you sure you want to approve this item?",
      transport_according_to: "Either total vehicle or total kg",
      percentage_value: "Use decimal value of persentage",
      over_due_debt:
        "Except the customer has over due debt, this is only for unsettled sale, ",
      print_again:
        "This item has already been printed %{count} times, type the reason why you want to print this again",
      print_content: "Are you sure you want to print this item?",
    },
    dialog: {
      create_sale_order: "Create Sale Order",
      edit_sale_order: "Edit Sale Order",
      create_sale: "Create Sale",
      edit_sale: "Edit Sale",
      create_sale_return: "Create Sale Return",
      edit_sale_return: "Edit Sale Return",
      create_promotion: "Create Promotion",
      edit_promotion: "Edit Promotion",
      create_factory_debt_payment: "Create Factory Debt Payment",
      edit_factory_debt_payment: "Edit Factory Debt Payment",
      create_factory_debt_correction: "Create Factory Debt Correction",
      edit_factory_debt_correction: "Edit Factory Debt Correction",
      create_customer_debt_payment: "Create Customer Debt Payment",
      edit_customer_debt_payment: "Edit Customer Debt Payment",
      create_customer_debt_correction: "Create Customer Debt Correction",
      create_customer: "Create Customer",
      edit_customer: "Edit Customer",
      edit_customer_debt_correction: "Edit Customer Debt Correction",
      create_check_cashing: "Create Check Cashing",
      edit_check_cashing: "Edit Check Cashing",
      create_journal: "Create Journal",
      edit_journal: "Edit Journal",
      create_bad_debt: "Create Bad Debt",
      create_product: "Create Product",
      edit_product: "Edit Product",
      create_bad_debt_installment: "Create Bad Debt Installment",
      edit_bad_debt_installment: "Edit Bad Debt Installment",
      create_bad_debt_correction: "Create Bad Debt Correction",
      edit_bad_debt_correction: "Edit Bad Debt Correction",
      create_bad_debt_check_cashing: "Create Bad Debt Check Cashing",
      edit_bad_debt_check_cashing: "Edit Bad Debt Check Cashing",
      create_purchase_order: "Create Purchase Order",
      edit_purchase_order: "Edit Purchase Order",
      create_mutation_repacking: "Create Repacking",
      edit_mutation_repacking: "Edit Repacking",
      create_mutation_inventory_write_off: "Create Inventory Write Off",
      edit_mutation_inventory_write_off: "Edit Inventory Write Off",
      print_delivery_note: "Print Delivery Note",
      print: "Print",
      create_price: "Create Price",
      edit_price: "Update Price",
      create_goods_out: "Create Goods Out",
      edit_goods_out: "Edit Goods Out",
      create_goods_in: "Create Goods In",
      edit_goods_in: "Edit Goods In",
    },
    notification: {
      "403_error": "Cannot update approved data",
      not_allowed: "Not Allowed",
      Not_Found: "Not found",
      need_start_date: "Need Start Date",
      need_end_date: "Need End Date",
    },
    label: {
      account: "Account",
      address: "Address",
      amount: "Amount",
      code: "Code",
      company: "Company",
      correction: "Correction",
      customer: "Customer",
      credit: "Credit",
      date: "Date",
      debt: "Debt",
      cancel: "Cancel",
      cashing: "Cashing",
      check_number: "Check number",
      coa_code: "COA code",
      customer_debt: "Customer Debt",
      customer_debts: "Customer Debts",
      debit: "Debit",
      discount_per_box: "Discount / box",
      due_date: "Due date",
      end_date: "End date",
      journal: "Journal",
      name: "Name",
      note: "Note",
      order_quantity: "Order quantity",
      package: "Package",
      persentage: "Persentage",
      percentage_in_decimal: "Percentage in decimal",
      price_per_box: "Price / box",
      product_code: "Product code",
      promotions: "Promotions",
      purchase_order: "Purchase order",
      rejected: "Rejected",
      sales_number: "Sales number",
      sent_quantity: "Sent quantity",
      special_discount_per_box: "Special discount / box",
      start_date: "Start date",
      total_correction: "Total correction",
      total_vehicle: "Total vehicle",
      transaction_date: "Transaction date",
      transport: "transport",
      type: "Type",
      settled: "Settled",
      unsettled: "Unsettled",
      sale_code: "Sale code",
      sale_item: "Sale item",
      warehouse: "Warehouse",
      return_quantity: "Return quantity",
      price: "Price",
      bad_debt: "Bad Debt",
      installment: "Installment",
      payment: "Payment",
      remaining_debt: "Remaining Debt",
      total_payment: "Total Payment",
      supplier: "Supplier",
      max_payment_days: "Max Payment Days",
      quality: "Quality",
      quantity: "Quantity",
      stocktake: "Stocktake",
      sample: "Sample",
      ref_number: "Ref. Number",
      cogs: "Cost of Goods Sold",
      kind: "Kind",
      active: "Active",
      operator_name: "Operator Name",
      warehouse_broke: "Warehouse Broke",
      transfer_between_depo: "Transfer Between Depo",
      warning: "Warning",
      reason: "Reason",
      cus_sup: "Customer/Supplier",
      brand: "Brand",
      size: "Size",
      motif: "Motif",
      density: "Density",
      color: "Color",
      grade: "Grade",
      buy_price: "Buy Price",
      sell_price: "Sell Price",
      location: "Location",
      total_in: "Total In",
      total_out: "Total Out",
      inventory: "Inventory",
      total: "Total",
      phone: "Phone",
      product: "Product",
      send_date: "Send Date",
      previous_stock: "Previous Stock",
      final_amount: "Final Amount",
    },
    radio_btn: {
      return_warehouse: "Return Warehouse",
      return_broken: "Return Broken",
    },
    list_title: {
      factory_debt_payment: "Debt Payment",
      factory_debt_correction: "Debt Correction",
      customer_debt_payment: "Debt Payment",
      customer_debt_correction: "Debt Correction",
      purchase_order: "Purchase Order",
      purchase_return: "Purchase Return",
      sale_order: "Sale Order",
      sale_return: "Sale Return",
      check_cashing: "Check Cashing",
      journal: "Journal",
      bad_debt: "Bad Debt",
      bad_debt_installment: "Bad Debt Installment",
      bad_debt_correction: "Bad Debt Correction",
      bad_debt_check_cashing: "Bad Debt Check Cashing",
      mutation_repacking: "Repacking",
      mutation_inventory_write_off: "Inventory Write Off",
      all_transaction_journal: "All Transaction Journal",
    },
    tbl_header: {
      payment: "Payment",
      journal: "Journal",
      check: "Check",
      approved: "Approved",
      date: "Date",
      due_date: "Due Date",
      remaining_debt: "Remaining Debt",
      transaction_date: "Transaction Date",
      description: "Description",
      debit: "Debit",
      credit: "Kredit",
      check_cashing_date: "Check Cashing Date",
      bank_name: "Bank Name",
      check_number: "Check Number",
      note: "Note",
      actions: "Actions",
      sale_code: "Sale Code",
      code: "Code",
      company: "Company",
      customer: "Customer",
      type: "Type",
      coa: "COA",
      coa_name: "COA Name",
      correction: "Correction",
      customer_debt_payment_code: "Debt Payment Code",
      bad_debt_installment_code: "Debt Installment Code",
      product_code: "Product Code",
      product_name: "Product Name",
      price: "Price",
      warehouse: "Warehouse",
      sent_qty: "Sent Qty",
      return_qty: "Return Qty",
      delivery_note: "Delivery Note",
      product: "Product",
      name: "Name",
      kind: "Kind",
      stock: "Stock",
      location: "Location",
      created_at: "Created At",
      created_by: "Created By",
      send_date: "Send Date",
    },
    btn: {
      approve: "Approve",
    },
    tooltip: {
      total_inventory_write_off: "Value might be changed after approve",
    },
  },
};
export default en;
