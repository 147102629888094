import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const correctionFilterList = (permissions) => {

  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      alwaysOn
      source="product_adj"
      resettable
      variant="outlined"
      label="mine.label.code"
    />,
    <TextInput
      alwaysOn
      source="product"
      label="mine.label.product"
      resettable
      variant="outlined"
    />,
    <TextInput
      alwaysOn
      source="mutation"
      label="mine.label.amount"
      resettable
      variant="outlined"
    />,
    <TextInput
      alwaysOn
      source="note"
      label="mine.label.note"
      resettable
      variant="outlined"
    />,
    <DateInput
      source="start_date"
      label="mine.label.start_date"
      variant="outlined"
      alwaysOn={true}
    />,
    <DateInput
      source="end_date"
      label="mine.label.end_date"
      variant="outlined"
      alwaysOn={true}
    />,
  ];
  
  if (permissions && !permissions.includes("admin_depo")) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        // label="Depo"
        sort={{ field: "companies.alias", order: "ASC" }}
        resettable
      >
        <AutocompleteInput optionText="alias" variant="outlined" label="Depo" />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};

export default correctionFilterList;
