const splitArrayToChunks = (array, chunkSize) => {
  if (!isNaN(chunkSize)) {
    if (chunkSize % 1 !== 0) {
      return array;
    }
  }
  if (chunkSize == 0) {
    return array;
  }
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunkedArray.push(chunk);
    // do whatever
  }
  return chunkedArray;
};
export default splitArrayToChunks;
