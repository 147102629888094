import { DeleteButton, SaveButton, Toolbar } from "react-admin";

const SaveDeleteBtns = () => {
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  );
};

export default SaveDeleteBtns;
