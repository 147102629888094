import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import simpleRestProvider from "ra-data-simple-rest";

const apiUrl = process.env.REACT_APP_API_URL + "/api/admin/v1"; // "http://127.0.0.1:3300";
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
  /* if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  } */
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`,
  };
  return fetchUtils.fetchJson(url, options);
};
const countHeader = "X-Total-Count";
// setting data provider see: https://github.com/marmelab/react-admin/tree/master/packages/ra-data-simple-rest
const baseDataProvider = simpleRestProvider(apiUrl, httpClient, countHeader);

const dataProvider = {
  ...baseDataProvider,
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    let mergedSort = [];
    if (params.meta) {
      // meta.sort adalah default sort yg diset hardcoded, bisa multi order ex: ["id ASC", "name DESC"]
      mergedSort = params.meta.sort.filter(
        (val, idx) => !val.includes(params.sort.field)
      );
    }

    // karena di database ID nya pake uuid
    // jadi sort id jangan diterapkan
    // masalah ini tdk ada fix: https://github.com/marmelab/react-admin/issues/4906
    if (params.sort.field !== "id") {
      mergedSort.unshift(params.sort.field + " " + params.sort.order);
    }

    const query = {
      sort: JSON.stringify(mergedSort), // defaultnya react-admin
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };
    let url;
    if (resource.includes("?")) {
      url = `${apiUrl}/${resource}&${stringify(query)}`;
    } else {
      url = `${apiUrl}/${resource}?${stringify(query)}`;
    }
    const options =
      countHeader === "Content-Range"
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {};

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total:
          countHeader === "Content-Range"
            ? parseInt(headers.get("content-range").split("/").pop(), 10)
            : parseInt(headers.get(countHeader.toLowerCase())),
      };
    });
  },

  update: (resource, params) => {
    if (!params.data.image || typeof params.data.image === "string") {
      return baseDataProvider.update(resource, params);
    }
    if (!params.data.image.rawFile) {
      // masuk sini saat update tp field image tidak di ganti
      params.data.image = "";
      return baseDataProvider.update(resource, params);
    }

    const formData = new FormData();

    const imgFields = [];
    // set field
    for (const key in params.data) {
      if (Object.hasOwnProperty.call(params.data, key)) {
        const value = params.data[key];
        if (value && value.rawFile) {
          imgFields.push({ name: key, value });
          continue;
        } else if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          formData.set(key, value);
        }
      }
    }
    // set image
    for (let i = 0; i < imgFields.length; i++) {
      const obj = imgFields[i];
      formData.set(obj.name, obj.value.rawFile);
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  create: (resource, params) => {
    if (!params.data.image || typeof params.data.image === "string") {
      return baseDataProvider.create(resource, params);
    }

    const formData = new FormData();

    const imgFields = [];
    // set field
    for (const key in params.data) {
      if (Object.hasOwnProperty.call(params.data, key)) {
        const value = params.data[key];
        if (value && value.rawFile) {
          imgFields.push({ name: key, value });
          continue;
        } else if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          formData.set(key, value);
        }
      }
    }
    // set image
    for (let i = 0; i < imgFields.length; i++) {
      const obj = imgFields[i];
      formData.set(obj.name, obj.value.rawFile);
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({ data: json }));
  },
  deleteMany: (resource, params) => {
    const query = {
      ids: JSON.stringify(params.ids),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "text/plain",
      }),
    }).then((res) => {
      return res.json; // {data: [1,2,3]}
    });
  },
  getListNoParams: (resource) => {
    return httpClient(`${apiUrl}/${resource}`).then(({ headers, json }) => {
      return json;
    });
  },
  userAccessList: (resource, params) => {
    const query = {
      username: params.username, // harus ada username, mau tarik data rolenya siapa?
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(
      ({ headers, json }) => {
        return json;
      }
    );
  },

  userAccessUpdate: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.username}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  provinceList: (resource) => {
    const query = {
      sort: JSON.stringify(["id ASC"]),
      range: JSON.stringify([0, 35]),
      filter: JSON.stringify({}),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`).then(
      ({ headers, json }) => {
        return json;
      }
    );
  },

  cityList: (params) =>
    httpClient(`${apiUrl}/cities/${params.provinceId}`).then(
      ({ json }) => {
        return json;
      } // array of object {id: ..., name: ...}
    ),

  getReport: (resource, params) => {
    const rangeStart = 0;
    const rangeEnd = 999;

    const query = {
      sort: params.meta
        ? JSON.stringify(params.meta.sort) // custom multi order ex: ["id ASC", "name DESC"]
        : JSON.stringify([params.sort.field + " " + params.sort.order]), // defaultnya react-admin
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
      type: params.type,
    };
    let url;
    if (resource.includes("?")) {
      url = `${apiUrl}/${resource}&${stringify(query)}`;
    } else {
      url = `${apiUrl}/${resource}?${stringify(query)}`;
    }
    const options = {
      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
      headers: new Headers({
        Accept: "application/json",
      }),
    };

    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
      };
    });
  },
};

export default dataProvider;
