import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  Create,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import DisabledInput from "../common/DisabledInput";

// const validateCode = [required(), maxLength(13)];
// const validateName = [required(), maxLength(125)];
const validateUnit = [required(), maxLength(10)];
// const validateSize = [required(), maxLength(20)];
const validateLocation = [required(), maxLength(20)];

export default function ProductAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Buat Produk Baru</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            {/* <AutocompleteInput
              variant="outlined"
              source="company_id"
              isRequired
              choices={isLoadingCompanies ? [] : companies}
              fullWidth
              validate={validateCompany}
            /> */}
            <DisabledInput />

            {/* <TextInput
              variant="outlined"
              source="unit"
              fullWidth
              isRequired
              validate={validateUnit}
              helperText="Contoh: Box, 25"
            /> */}

            {/* <TextInput
              variant="outlined"
              source="location"
              fullWidth
              isRequired
              validate={validateLocation}
              helperText="Lokasi di gudang"
            /> */}
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
