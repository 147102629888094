import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  TextInput,
  Edit,
  useResourceContext,
  required,
  number,
  choices,
  useRefresh,
  useDataProvider,
  AutocompleteInput,
  NumberInput,
  minValue,
  ReferenceInput,
  minLength,
  maxLength,
  DateInput,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import ProductOutForm from "../components/ProductOutForm";

const validateProduct = [required(), minLength(36), maxLength(36)];
const validateType = [
  required(),
  choices(["in", "out", "adj"], "Please choose one of the values"),
];
const validateNote = [maxLength(100)];

const filterToQuery = (searchText) => ({ code: searchText });

export default function GoodsOutEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();
  const t = useTranslate();

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS

  const save = useCallback(
    async (values) => {
      try {
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.edit_goods_out")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <DateInput
              variant="outlined"
              source="send_date"
              label="mine.label.date"
              fullWidth
            />
            <ReferenceInput
              label="mine.label.customer"
              source="customer_id"
              reference="customers"
              // perPage={10}
            >
              <AutocompleteInput
                variant="outlined"
                isRequired
                fullWidth
                validate={validateProduct}
                filterToQuery={filterToQuery}
                optionText="name"
              />
            </ReferenceInput>

            <ProductOutForm isEdit={true} />

            <TextInput
              disabled
              source="type"
              defaultValue="out"
              sx={{ display: "none" }}
              validate={validateType}
            />

            {/* <SelectInput
              variant="outlined"
              source="type"
              choices={[
                { id: "in", name: "Masuk" },
                { id: "out", name: "Keluar" },
                { id: "adj", name: "Penyesuaian" },
              ]}
              fullWidth
              isRequired
              validate={validateType}
            /> */}

            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
