import indonesiaMessages from "ra-language-indonesian-new";
const id = {
  ra: {
    ...indonesiaMessages.ra,
    ...indonesiaMessages.ra.notification,
    action: {
      ...indonesiaMessages.ra.action,
      clear_array_input: "Kosongkan list",
      select_columns: "Kolom",
    },
    message: {
      ...indonesiaMessages.ra.message,
      clear_array_input: "Apakah anda yakin ingin membersihkan seluruh list?",
    },
  },
  resources: {
    company: {
      name: "Perusahaan",
    },
    user: {
      name: "Pengguna",
    },
    chart_of_account: {
      name: "Grafik Akun",
    },
    sales_area: {
      name: "Area Jual",
    },
    customer_type: {
      name: "Tipe Pelanggan",
    },
    supplier: {
      name: "Pemasok",
    },
    brand: {
      name: "Merek",
    },
    size: {
      name: "Ukuran",
    },
    kind: {
      name: "Jenis",
    },
    density: {
      name: "Kepadatan",
    },
    color: {
      name: "Warna",
    },
    quality: {
      name: "Kualitas",
    },
    product: {
      name: "Produk",
    },
    warehouse: {
      name: "Gudang",
    },
    transport: {
      name: "Angkutan",
    },
    order: {
      name: "Pesan |||| Pesanan",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    sale: {
      name: "Jual |||| Penjualan",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    purchase: {
      name: "Beli |||| Pembelian",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    return: {
      name: "Retur |||| Pengembalian",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    customer: {
      name: "Pelanggan",
      fields: {
        first_name: "First name",
        last_name: "Last name",
        dob: "Date of birth",
      },
    },
    price: {
      name: "Harga",
      // fields: {
      //   customer_type: "Jenis pelanggan",
      //   price: "Batas harga",
      //   note: "Keterangan",
      // },
    },
    price_limit: {
      name: "Batas Harga",
      fields: {
        customer_type: "Jenis pelanggan",
        price: "Batas harga",
        note: "Keterangan",
      },
    },
    brand_price_addition: {
      name: "Tambah Harga Brand",
    },
    size_price_addition: {
      name: "Tambah Harga Ukuran",
    },
    debt_payment: {
      name: "Bayar",
    },
    debt_correction: {
      name: "Koreksi",
    },
    check_cashing: {
      name: "Pencairan Cek/Giro",
    },
    journal: {
      name: "Jurnal",
    },
    correction: {
      name: "Koreksi",
    },
    mutation: {
      name: "Mutasi",
    },
    stock_mutation: {
      name: "Mutasi Stok",
    },
    in: {
      name: "Masuk",
    },
    out: {
      name: "Keluar",
    },
    bad_debt: {
      name: "Piutang Macet",
    },
    bad_debt_installment: {
      name: "Cicilan",
    },
    bad_debt_correction: {
      name: "Koreksi",
    },
    bad_debt_check_cashing: {
      name: "Cair Giro",
    },
    mutation_repacking: {
      name: "Packing Ulang",
    },
    mutation_inventory_write_off: {
      name: "Pemusnahan",
    },
    mutation_change_goods_code: {
      name: "Ganti Kode Barang",
    },
    mutation_change_location: {
      name: "Pindah Antar Lokasi",
    },
    all_transaction_journals: {
      name: "Jurnal Seluruh Transaksi",
    },
    activity: {
      name: "Aktifitas",
    },
  },
  mine: {
    filter: {
      search_all: "Cari semua",
    },
    expand_menu: {
      purchase: "Pembelian",
      sale: "Penjualan",
      item: "Komponen",
      factory_debt: "Hutang ke pabrik",
      customer_debt: "Piutang pelanggan",
      bad_debt: "Piutang Macet",
      mutation: "Mutasi",
      report: "Laporan",
      general_ledger: "General Ledger",
    },
    message: {
      approve: "Setujui",
      approve_content: "Apakah anda yakin ingin menyetujui data ini?",
      transport_according_to: "Isi berdasarkan jumlah mobil atau jumlah kg",
      percentage_value: "Isi dengan nilai desimal dari persentase",
      over_due_debt:
        "Hanya untuk penjualan yg belum lunas, kecuali pelanggan punya hutang melebihi batas waktu",
      print_again:
        "Data ini sudah pernah dicetak %{count} kali, ketik alasan anda kenapa ingin cetak lagi",
      print_content: "Apakah anda yakin ingin cetak data ini?",
    },
    dialog: {
      create_sale_order: "Buat Pesanan Penjualan",
      edit_sale_order: "Rubah Pesanan Penjualan",
      create_sale: "Buat Penjualan",
      edit_sale: "Rubah Penjualan",
      create_sale_return: "Buat Retur Penjualan",
      edit_sale_return: "Rubah Retur Penjualan",
      create_promotion: "Buat Promosi",
      edit_promotion: "Rubah Promosi",
      create_factory_debt_payment: "Buat Pembayaran Hutang Ke Pabrik",
      edit_factory_debt_payment: "Rubah Pembayaran Hutang Ke Pabrik",
      create_factory_debt_correction: "Buat Koreksi Hutang Ke Pabrik",
      edit_factory_debt_correction: "Rubah Koreksi Hutang Ke Pabrik",
      create_customer_debt_payment: "Buat Pembayaran Hutang Pelanggan",
      edit_customer_debt_payment: "Rubah Pembayaran Hutang Pelanggan",
      create_customer_debt_correction: "Buat Koreksi Piutang Pelanggan",
      create_customer: "Buat Pelanggan",
      edit_customer: "Rubah Pelanggan",
      edit_customer_debt_correction: "Rubah Koreksi Hutang Pelanggan",
      create_check_cashing: "Buat Pencairan Cek/Giro",
      edit_check_cashing: "Rubah Pencairan Cek/Giro",
      create_journal: "Buat Jurnal",
      edit_journal: "Rubah Jurnal",
      create_bad_debt: "Buat Piutang Macet",
      create_product: "Buat Produk",
      edit_product: "Rubah Produk",
      create_bad_debt_installment: "Buat Cicilan Piutang Macet",
      edit_bad_debt_installment: "Rubah Cicilan Piutang Macet",
      create_bad_debt_correction: "Buat Koreksi Piutang Macet",
      edit_bad_debt_correction: "Rubah Koreksi Piutang Macet",
      create_bad_debt_check_cashing: "Buat Pencairan Cek/Giro Piutang Macet",
      edit_bad_debt_check_cashing: "Rubah Pencairan Cek/Giro Piutang Macet",
      create_purchase_order: "Buat Pesan Pembelian",
      edit_purchase_order: "Rubah Pesan Pembelian",
      create_mutation_repacking: "Buat Packing Ulang",
      edit_mutation_repacking: "Edit Packing Ulang",
      create_mutation_inventory_write_off: "Buat Pemusnahan Barang",
      edit_mutation_inventory_write_off: "Rubah Pemusnahan Barang",
      print_delivery_note: "Cetak Surat Jalan",
      print: "Cetak",
      create_price: "Buat Price",
      edit_price: "Rubah Price",
      create_goods_out: "Buat Barang Keluar",
      edit_goods_out: "Rubah Barang Keluar",
      create_goods_in: "Buat Barang Masuk",
      edit_goods_in: "Rubah Barang Masuk",
    },
    notification: {
      "403_error": "Tidak dapat merubah data yang telah disetujui",
      not_allowed: "Tidak Diperbolehkan",
      Not_Found: "Tidak ditemukan",
      need_start_date: "Isi Tanggal Awal dulu",
      need_end_date: "Isi Tanggal Akhir dulu",
    },
    label: {
      account: "Akun",
      address: "Alamat",
      amount: "Jumlah",
      code: "Kode",
      company: "Perusahaan",
      correction: "Koreksi",
      customer: "Pelanggan",
      credit: "Kredit",
      date: "Tanggal",
      debt: "Hutang",
      cancel: "Pembatalan",
      cashing: "Pencairan",
      check_number: "No. Cek / Giro",
      coa_code: "Kode COA",
      customer_debt: "Piutang",
      customer_debts: "Daftar Piutang",
      debit: "Debit",
      discount_per_box: "Diskon / box",
      due_date: "Tanggal jatuh tempo",
      end_date: "Tanggal akhir",
      journal: "Jurnal",
      name: "Nama",
      note: "Keterangan",
      order_quantity: "Jumlah pesanan",
      package: "Paket",
      persentage: "Persentase",
      percentage_in_decimal: "Persentase dalam desimal",
      price_per_box: "Harga / box",
      product_code: "Kode produk",
      promotions: "Promosi",
      purchase_order: "Pesan beli",
      rejected: "Penolakan",
      sales_number: "Nomer penjualan",
      sent_quantity: "Jumlah kirim",
      special_discount_per_box: "Spesial diskon / box",
      start_date: "Tanggal awal",
      total_correction: "Jumlah koreksi",
      total_vehicle: "Jumlah angkutan",
      transaction_date: "Tanggal transaksi",
      transport: "Angkutan",
      type: "Tipe",
      settled: "Lunas",
      unsettled: "Belum Lunas",
      sale_code: "Kode jual",
      sale_item: "Item jual",
      warehouse: "Gudang",
      return_quantity: "Jumlah retur",
      price: "Harga",
      bad_debt: "Piutang Macet",
      installment: "Cicilan",
      payment: "Pembayaran",
      remaining_debt: "Sisa Hutang",
      total_payment: "Total Bayar",
      supplier: "Pemasok",
      max_payment_days: "Max Hari Pembayaran",
      quality: "Kualitas",
      quantity: "Jumlah",
      stocktake: "Opname",
      sample: "Sampel",
      ref_number: "Nomer Surat Jalan",
      cogs: "HPP",
      kind: "Jenis",
      active: "Aktif",
      operator_name: "Nama Operator",
      warehouse_broke: "Pecah Gudang",
      transfer_between_depo: "Mutasi Antar Depo",
      warning: "Peringatan",
      reason: "Alasan",
      cus_sup: "Pelanggan/Pemasok",
      brand: "Merek",
      size: "Ukuran",
      motif: "Motif",
      density: "Kepadatan",
      color: "Warna",
      grade: "Kualitas",
      buy_price: "Harga Beli",
      sell_price: "Harga Jual",
      location: "Lokasi",
      total_in: "Total Masuk",
      total_out: "Total Keluar",
      inventory: "Stok",
      total: "Jumlah",
      phone: "No. Hp",
      product: "Produk",
      send_date: "Tanggal Kirim",
      previous_stock: "Stok Sebelumnya",
      final_amount: "Jumlah Akhir",
    },
    radio_btn: {
      return_warehouse: "Masuk Gudang",
      return_broken: "Tidak Masuk Gudang / Rusak",
    },
    list_title: {
      factory_debt_payment: "Bayar Hutang",
      factory_debt_correction: "Koreksi Hutang",
      customer_debt_payment: "Bayar Piutang",
      customer_debt_correction: "Koreksi Piutang",
      purchase_order: "Pesan Beli",
      purchase_return: "Retur Beli",
      sale_order: "Pesan Jual",
      sale_return: "Retur Jual",
      check_cashing: "Pencairan Cek/Giro",
      journal: "Jurnal",
      bad_debt: "Piutang Macet",
      bad_debt_installment: "Cicilan Piutang Macet",
      bad_debt_correction: "Koreksi Piutang Macet",
      bad_debt_check_cashing: "Pencairan Cek/Giro Piutang Macet",
      mutation_repacking: "Packing Ulang",
      mutation_inventory_write_off: "Pemusnahan Barang",
      all_transaction_journal: "Jurnal Seluruh Transaksi",
    },
    tbl_header: {
      payment: "Pembayaran",
      journal: "Jurnal",
      check: "Giro",
      approved: "Disetujui",
      date: "Tanggal",
      due_date: "Jatuh Tempo",
      remaining_debt: "Sisa Hutang",
      transaction_date: "Tanggal Transaksi",
      description: "Deskripsi",
      debit: "Debit",
      credit: "Kredit",
      check_cashing_date: "Tanggal Pencairan",
      bank_name: "Nama Bank",
      check_number: "Giro No.",
      note: "Keterangan",
      actions: "Aksi",
      sale_code: "Kode Jual",
      code: "Kode",
      company: "Perusahaan",
      customer: "Pelanggan",
      type: "Tipe",
      coa: "Akun",
      coa_name: "Nama Akun",
      correction: "Koreksi",
      customer_debt_payment_code: "Kode Bayar Piutang",
      bad_debt_installment_code: "Kode Cicil Piutang",
      product_code: "Kode Produk",
      product_name: "Nama Produk",
      price: "harga",
      warehouse: "Gudang",
      sent_qty: "Jumlah Kirim",
      return_qty: "Jumlah Retur",
      delivery_note: "Nomer Surat Jalan",
      product: "Produk",
      name: "Nama",
      kind: "Jenis",
      stock: "Stok",
      location: "Lokasi",
      created_at: "Dibuat Pada",
      created_by: "Dibuat Oleh",
      send_date: "Tanggal Kirim",
    },
    btn: {
      approve: "Setujui",
    },
    tooltip: {
      total_inventory_write_off: "Nilainya bisa berbeda setelah di approve",
    },
  },
};
export default id;
