import { Menu, usePermissions } from "react-admin";

const MyMenu = () => {
  const { permissions } = usePermissions();

  return (
    <Menu>
      <Menu.DashboardItem />
      {permissions &&
      (permissions.includes("administrator") ||
        permissions.includes("depo_support")) ? (
        <Menu.ResourceItem name="companies" />
      ) : null}
      <Menu.ResourceItem name="sales-areas" />
      <Menu.ResourceItem name="customers" />
      <Menu.ResourceItem name="products" />
      <Menu.ResourceItem name="goods-in" />
      <Menu.ResourceItem name="goods-out" />
      <Menu.ResourceItem name="corrections" />
      <Menu.ResourceItem name="summaries" />
      {permissions && permissions.includes("administrator")
        ? [
            <Menu.ResourceItem key="users" name="users" />,
            <Menu.ResourceItem key="activities" name="activities" />,
          ]
        : null}
    </Menu>
  );
};
export default MyMenu;
