import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useDataProvider,
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  SelectInput,
  Create,
  DateInput,
  PasswordInput,
  useResourceContext,
  useRefresh,
  required,
  choices,
  maxLength,
  email,
} from "react-admin";
import ImageDropzone from "../../imageDropzone/ImageDropzone";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";

const passwordConfirmation = (value, allValues) => {
  if (value !== allValues.password) {
    return "Password doesn't match";
  }
  return undefined;
};

const validateCompany = [required()];
const validateFirstName = [required(), maxLength(100)];
const validateLastName = [maxLength(100)];
const validateGender = [
  choices(["Male", "Female"], "Please choose one of the values"),
];
const validateUsername = [required(), maxLength(50)];
const validateEmail = [required(), email(), maxLength(50)];
const validateRole = [required()];
const validatePassword = [required()];
const validatePasswordConfirmation = [required(), passwordConfirmation];

export default function UserAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const resource = useResourceContext();
  const refresh = useRefresh();

  const dataProvider = useDataProvider();

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS

  // GET ROLE LISTS
  const {
    data: roleMaster,
    isLoading: isLoadingRoles,
    error: roleError,
  } = useQuery(["user-access/master", "getListNoParams"], () =>
    dataProvider.getListNoParams("user-access/master")
  );
  // GET ROLE LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Create User</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            {/* <ImageDropzone /> */}

            <SelectInput
              variant="outlined"
              source="company_id"
              isRequired
              choices={isLoadingCompanies ? [] : companies}
              fullWidth
              validate={validateCompany}
            />

            <TextInput
              variant="outlined"
              source="first_name"
              fullWidth
              isRequired
              validate={validateFirstName}
            />

            <TextInput
              variant="outlined"
              source="last_name"
              fullWidth
              validate={validateLastName}
            />

            <SelectInput
              variant="outlined"
              source="gender"
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
              fullWidth
              validate={validateGender}
            />

            <DateInput
              variant="outlined"
              source="birth_date"
              fullWidth
              sx={{ display: "none" }}
            />

            <TextInput
              variant="outlined"
              source="username"
              fullWidth
              isRequired
              validate={validateUsername}
            />

            <TextInput
              variant="outlined"
              source="email"
              fullWidth
              isRequired
              validate={validateEmail}
            />

            <SelectInput
              variant="outlined"
              source="role"
              isRequired
              choices={isLoadingRoles ? [] : roleMaster}
              fullWidth
              validate={validateRole}
            />

            <PasswordInput
              variant="outlined"
              source="password"
              fullWidth
              isRequired
              validate={validatePassword}
            />

            <PasswordInput
              variant="outlined"
              source="password_confirmation"
              fullWidth
              isRequired
              validate={validatePasswordConfirmation}
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
