import { NumberInput, TextInput } from "react-admin";

const salesAreaFilterList = [
  <TextInput source="all" 
  label="mine.filter.search_all" alwaysOn resettable />,
  <TextInput source="company" resettable />,
  <NumberInput source="code" />,
  <TextInput source="name" resettable />,
];

export default salesAreaFilterList;
