import {
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
  useDataProvider,
  useListContext,
  useNotify,
  CreateButton,
  ExportButton,
  useResourceContext,
} from "react-admin";
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useMutation } from "react-query";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CompleteWithCustomExportActions = ({ createHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { filterValues, sort } = useListContext();
  const resource = useResourceContext();
  const clsk = React.useRef(null);
  const irnf = React.useRef(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const {
    data: daSum,
    mutate: muSum,
    error: errorSum,
  } = useMutation(() =>
    dataProvider.getReport(resource + "/report", {
      sort: sort.field === "id" ? { field: "created_at", order: "ASC" } : sort,
      filter: filterValues,
      type: "summary",
    })
  );
  const {
    data: daDet,
    mutate: muDet,
    error: errorDet,
  } = useMutation(() =>
    dataProvider.getReport(resource + "/report", {
      sort: sort.field === "id" ? { field: "created_at", order: "ASC" } : sort,
      filter: filterValues,
      type: "detail",
    })
  );
  React.useEffect(() => {
    if (daSum) {
      clsk.current.click();
    }
  }, [daSum]);
  React.useEffect(() => {
    if (daDet) {
      irnf.current.click();
    }
  }, [daDet]);
  React.useEffect(() => {
    if (errorSum) {
      notify("mine.notification." + errorSum.message, { type: "error" });
    }
    if (errorDet) {
      notify("mine.notification." + errorDet.message, { type: "error" });
    }
  }, [errorSum, errorDet]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <TopToolbar
      sx={{
        minHeight: "54px !important",
        mb: 1,
        "& .MuiButtonBase-root": {
          border: "0.1rem solid",
        },
      }}
    >
      <SelectColumnsButton />
      <FilterButton />
      {createHandler ? (
        <CreateButton resource={undefined} onClick={createHandler} />
      ) : null}
      <ExportButton exporter={() => {}} resource="/" onClick={handleClick} />
      <div>
        {daSum ? (
          <a
            ref={clsk}
            href={process.env.REACT_APP_API_URL + "/" + daSum.data.url}
          />
        ) : null}
        {daDet ? (
          <a
            ref={irnf}
            href={process.env.REACT_APP_API_URL + "/" + daDet.data.url}
          />
        ) : null}
        <StyledMenu
          id="export-menu"
          MenuListProps={{
            "aria-labelledby": "export-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => muSum()} disableRipple>
            <EditIcon />
            Summary
          </MenuItem>
          <MenuItem onClick={() => muDet()} disableRipple>
            <FileCopyIcon />
            Detail
          </MenuItem>
        </StyledMenu>
      </div>
    </TopToolbar>
  );
};

export default CompleteWithCustomExportActions;
