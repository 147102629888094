import { AutocompleteInput, ReferenceInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const productFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="name"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.name"
  />,
  <TextInput
    source="color"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.color"
  />,
  <TextInput
    source="size"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.size"
  />,
];

export const administratorProductFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    alwaysOn
    source="company_id"
    reference="companies"
    // label="Depo"
    sort={{ field: "companies.alias", order: "ASC" }}
    resettable
  >
    <AutocompleteInput optionText="alias" variant="outlined" label="Depo" />
  </ReferenceInput>,
  <TextInput
    source="code"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.code"
  />,
  <TextInput
    source="name"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.name"
  />,
  <TextInput
    source="size"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.size"
  />,
  <TextInput
    source="location"
    alwaysOn
    resettable
    variant="outlined"
    label="mine.label.location"
  />,
];

export default productFilterList;
