import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useCreate,
  useNotify,
  SimpleForm,
  TextInput,
  Create,
  useResourceContext,
  required,
  number,
  choices,
  useRefresh,
  AutocompleteInput,
  NumberInput,
  minValue,
  ReferenceInput,
  minLength,
  maxLength,
  useTranslate,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import ProductInForm from "../components/ProductInForm";

const validateProduct = [required(), minLength(36), maxLength(36)];
const validateMutation = [required(), number(), minValue(1)];
const validateType = [
  required(),
  choices(["in", "out", "adj"], "Please choose one of the values"),
];
const validateNote = [maxLength(100)];

const filterToQuery = (searchText) => ({ code: searchText });

export default function GoodsInAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const t = useTranslate()

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Create title={" "} sx={{ "& .RaCreate-main": { mt: 0 } }}>
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.create_goods_in")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <ProductInForm />

            <TextInput
              disabled
              source="type"
              defaultValue="in"
              sx={{ display: "none" }}
              validate={validateType}
            />

            {/* <SelectInput
              variant="outlined"
              source="type"
              choices={[
                { id: "in", name: "Masuk" },
                { id: "out", name: "Keluar" },
                { id: "adj", name: "Penyesuaian" },
              ]}
              fullWidth
              isRequired
              validate={validateType}
            /> */}

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
