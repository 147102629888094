import { NumberInput, TextInput } from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const companyFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="alias"
    alwaysOn
    label="mine.label.name"
    resettable
    variant="outlined"
  />,
  <TextInput
    source="detail"
    alwaysOn
    resettable
    label="mine.label.address"
    variant="outlined"
    fullWidth
  />,
  <NumberInput source="phone" alwaysOn resettable variant="outlined" />,
  <NumberInput source="fax" alwaysOn resettable variant="outlined" />,
];

export default companyFilterList;
