import { useEffect, useState } from "react";
import { AutocompleteInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";

const CityInput = (props) => {
  const [cities, setCities] = useState([]);
  const dataProvider = useDataProvider();
  const provinceId = useWatch({ name: "province_id" });
  useEffect(() => {
    if (provinceId) {
      (async () => {
        const data = await dataProvider.cityList({ provinceId });
        setCities(data);
      })();
    }
  }, [provinceId]);
  return <AutocompleteInput choices={cities} {...props} />;
};

export default CityInput;
