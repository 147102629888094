import Grid from "@mui/material/Grid";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
} from "react-admin";
import {
  OptionRenderer,
  orFilterToQuery,
  orInputText,
  orMatchSuggestion,
} from "../../common/OptionRenderer";

const validateMutation = [required(), number(), minValue(1)];
// const filterToQuery = (searchText) => ({ code: searchText });
const validateProduct = [required(), minLength(36), maxLength(36)];

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  ...rest
}) => {
  const productIdSource = getSource("product_id");
  const mutationSource = getSource("mutation");
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12}>
        <ReferenceInput
          source={productIdSource}
          reference="products"
          // perPage={10}
        >
          <AutocompleteInput
            label="mine.label.product_code"
            variant="outlined"
            isRequired
            fullWidth
            validate={validateProduct}
            optionText={<OptionRenderer />}
            inputText={orInputText}
            matchSuggestion={orMatchSuggestion}
            filterToQuery={orFilterToQuery}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <NumberInput
          variant="outlined"
          source={mutationSource}
          label="mine.label.total"
          fullWidth
          isRequired
          validate={validateMutation}
        />
      </Grid>
    </Grid>
  );
};
export default Inputs;
