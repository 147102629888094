import {
  List,
  TextField,
  DatagridConfigurable,
  ReferenceField,
  DateField,
  FunctionField,
} from "react-admin";
import activityFilterList from "../../components/activities/filter/activityFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";

const ActivityList = () => {
  return (
    <List
      empty={false}
      actions={<TableListActions />}
      filters={activityFilterList}
      queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      sx={{
        "& .RaList-main > .MuiToolbar-root": {
          flexDirection: "column",
          alignItems: "baseline",
        },
      }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        {/* <ReferenceField source="company_id" reference="companies/some">
          <TextField source="alias" />
        </ReferenceField> */}
        <ReferenceField source="user_id" reference="users/some">
          <FunctionField
            label="Name"
            render={(record) => {
              return `${record.first_name} ${record.last_name}`;
            }}
          />
        </ReferenceField>
        <TextField source="action_url" />
        <TextField source="req_method" />
        <TextField source="note" />
        <TextField source="ip" />
        <DateField source="created_at" showTime />
      </DatagridConfigurable>
    </List>
  );
};

export default ActivityList;
