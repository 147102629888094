import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const customerFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="name"
    label="mine.label.name"
    alwaysOn
    resettable
    variant="outlined"
  />,
  <TextInput
    source="code"
    label="mine.label.code"
    variant="outlined"
    alwaysOn
    resettable
  />,
  <TextInput source="pic" alwaysOn resettable variant="outlined" />,
  <ReferenceInput
    alwaysOn
    source="sales_area_id"
    reference="sales-areas"
    // label="Depo"
    sort={{ field: "sales_areas.code", order: "ASC" }}
    resettable
  >
    <AutocompleteInput
      optionText="name"
      variant="outlined"
      label="resources.sales_area.name"
    />
  </ReferenceInput>,
];

export const administratorCustomerFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <TextInput
    source="name"
    label="mine.label.name"
    alwaysOn
    resettable
    variant="outlined"
  />,
  <TextInput source="pic" alwaysOn resettable variant="outlined" />,
  <TextInput
    source="code"
    label="mine.label.code"
    variant="outlined"
    alwaysOn
    resettable
  />,
  <ReferenceInput
    alwaysOn
    source="sales_area_id"
    reference="sales-areas"
    // label="Depo"
    sort={{ field: "sales_areas.code", order: "ASC" }}
    resettable
  >
    <AutocompleteInput
      optionText="name"
      variant="outlined"
      label="resources.sales_area.name"
    />
  </ReferenceInput>,
  <ReferenceInput
    alwaysOn
    source="company_id"
    reference="companies"
    // label="Depo"
    sort={{ field: "companies.alias", order: "ASC" }}
    resettable
  >
    <AutocompleteInput optionText="alias" variant="outlined" label="Depo" />
  </ReferenceInput>,
];

export default customerFilterList;
