import polyglotI18nProvider from "ra-i18n-polyglot";
import en from "./i18n/en";
import id from "./i18n/id";

const translations = { en, id };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "id", // default locale
  [
    { locale: "id", name: "Indonesia" },
    { locale: "en", name: "English" },
  ]
);
