import EditIcon from "@mui/icons-material/Edit";
import { Button } from "react-admin";

const EditBtn = ({ editHandler }) => {
  return (
    <Button
      startIcon={<EditIcon />}
      label="ra.action.edit"
      sx={{ width: "fit-content" }}
      onClick={editHandler}
    />
  );
};
export default EditBtn;