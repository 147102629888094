import { Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import UserList from "./pages/users/UserList";
import ActivityList from "./pages/activities/ActivityList";
import CompanyList from "./pages/companies/CompanyList";
import ProductList from "./pages/products/ProductList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CustomerList from "./pages/customers/CustomerList";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import CorrectionList from "./pages/corrections/CorrectionList";
import SummaryList from "./pages/reports/SummaryList";
import GoodsOutList from "./pages/goodsOut/GoodsOutList";
import GoodsInList from "./pages/goodsIn/GoodsInList";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MyLayout from "./components/layout/MyLayout";
import { i18nProvider } from "./i18nProvider";
import { defaultTheme } from "react-admin";
import A from "./pages/dashboard/A";
import SalesAreaList from "./pages/salesAreas/SalesAreaList";

const darkTheme = {
  ...defaultTheme,
  palette: { mode: "dark" },
};

const App = () => {
  return (
    <Admin
      dashboard={A}
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
      theme={defaultTheme}
      darkTheme={darkTheme}
      defaultTheme="dark"
      i18nProvider={i18nProvider}
      requireAuth
    >
      {(permissions) => {
        return (
          <>
            <Resource
              name="companies"
              list={
                permissions &&
                (permissions.includes("administrator") ||
                  permissions.includes("depo_support"))
                  ? CompanyList
                  : null
              }
              icon={LocationCityIcon}
              options={{ label: "Depo" }}
              // edit={UserEdit}
              // create={UserCreate}
              // show={UserShow}
            />
            <Resource
              name="sales-areas"
              list={SalesAreaList}
              icon={ChangeCircleIcon}
              options={{ label: "resources.sales_area.name" }}
            />
            <Resource
              name="customers"
              list={CustomerList}
              icon={StoreIcon}
              options={{ label: "resources.customer.name" }}
            />
            <Resource
              name="products"
              list={ProductList}
              icon={CategoryIcon}
              options={{ label: "resources.product.name" }}
            />
            <Resource
              name="goods-in"
              list={GoodsInList}
              icon={AddCircleIcon}
              options={{ label: "resources.in.name" }}
            />
            <Resource
              name="goods-out"
              list={GoodsOutList}
              icon={RemoveCircleIcon}
              options={{ label: "resources.out.name" }}
            />
            <Resource
              name="corrections"
              list={CorrectionList}
              icon={ChangeCircleIcon}
              options={{ label: "resources.correction.name" }}
            />
            <Resource
              name="summaries"
              list={SummaryList}
              icon={AssessmentIcon}
              options={{ label: "resources.stock_mutation.name" }}
            />
            <Resource
              name="users"
              list={
                permissions && permissions.includes("administrator")
                  ? UserList
                  : null
              }
              icon={PeopleAltIcon}
              // edit={UserEdit}
              // create={UserCreate}
              // show={UserShow}
            />
            <Resource
              name="activities"
              list={
                permissions && permissions.includes("administrator")
                  ? ActivityList
                  : null
              }
              icon={ListAltIcon}
            />
          </>
        );
      }}
    </Admin>
  );
};

export default App;
