import { useMemo, useState } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  ReferenceField,
  useTheme,
  usePermissions,
} from "react-admin";
import GoodsInAddDialog from "../../components/goodsIns/create/GoodsInAddDialog";
import GoodsInEditDialog from "../../components/goodsIns/edit/GoodsInEditDialog";
import goodsInFilterList from "../../components/goodsIns/filter/goodsInFilterList";
import GoodsInExpandList from "../../components/goodsIns/expand/GoodsInExpandList";
import CompleteWithCustomExportActions from "../../components/tableListActions/CompleteWithCustomExportActions";

const GoodsInList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  // const handleClickOpenEdit = (recId) => () => {
  //   setRecordId(recId);
  //   setOpenEdit(true);
  // };
  const list = useMemo(
    () => (
      <List
        // resource="transactions"
        // storeKey="goodsIn"
        title="Barang Masuk"
        empty={false}
        actions={
          <CompleteWithCustomExportActions createHandler={handleClickOpenAdd} />
        }
        filters={goodsInFilterList(permissions)}
        filter={{ type: "in" }}
        sort={{ field: "created_at", order: "DESC" }}
        // queryOptions={{ meta: { sort: ["transactions.created_at DESC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable
          expand={<GoodsInExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme === "light" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField source="company.alias" label="Depo" />
          ) : null}
          <TextField source="purchase_order.code" label="mine.label.code" />
          <TextField source="note" label="mine.tbl_header.note" emptyText="-" />
          <DateField
            source="created_at"
            label="mine.tbl_header.created_at"
            showTime
          />
          <ReferenceField
            label="mine.tbl_header.created_by"
            source="created_by"
            reference="users/some"
          >
            <TextField source="username" />
          </ReferenceField>
          {/* <WrapperField label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField> */}
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      {openAdd ? (
        <GoodsInAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <GoodsInEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default GoodsInList;
