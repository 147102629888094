import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { TextInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";

const ProductCodeDetail = ({ name, stock, dependOn, resource, queryName }) => {
  const dataProvider = useDataProvider();
  const dependOnVal = useWatch({ name: dependOn });
  const { setValue } = useFormContext();
  useEffect(() => {
    if (dependOnVal === null) {
      setValue(name, undefined);
      setValue(stock, undefined);
    } else if (dependOnVal) {
      (async () => {
        const data = await dataProvider.getListNoParams(
          resource + "/code-detail?" + queryName + "=" + dependOnVal
        );
        setValue(name, data.name);
        setValue(stock, data.stock);
      })();
    }
  }, [dependOnVal, name, stock]);
  return (
    <Grid item xs={12} md={6} padding={0}>
      <TextInput disabled source={stock} variant="outlined" fullWidth />
    </Grid>
  );
};
export default ProductCodeDetail;
