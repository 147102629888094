import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const summaryFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    alwaysOn
    source="product_id"
    reference="products"
    // label="Depo"
    sort={{ field: "products.name", order: "ASC" }}
    resettable
  >
    <AutocompleteInput
      optionText="name"
      variant="outlined"
      label="mine.label.product"
    />
  </ReferenceInput>,
  <TextInput
    source="code"
    label="mine.label.code"
    resettable
    variant="outlined"
    alwaysOn={true}
  />,
  <DateInput
    source="start_date"
    label="mine.label.start_date"
    variant="outlined"
    alwaysOn={true}
  />,
  <DateInput
    source="end_date"
    label="mine.label.end_date"
    variant="outlined"
    alwaysOn={true}
  />,
];

export const administratorSummaryFilterList = [
  <TextInput
    source="all"
    label="mine.filter.search_all"
    alwaysOn
    resettable
    variant="outlined"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    alwaysOn
    source="company_id"
    reference="companies"
    // label="Depo"
    sort={{ field: "companies.alias", order: "ASC" }}
    resettable
  >
    <AutocompleteInput optionText="name" variant="outlined" label="Depo" />
  </ReferenceInput>,
  <ReferenceInput
    alwaysOn
    source="product_id"
    reference="products"
    // label="Depo"
    sort={{ field: "products.name", order: "ASC" }}
    resettable
  >
    <AutocompleteInput
      optionText="name"
      variant="outlined"
      label="mine.label.product"
    />
  </ReferenceInput>,
  <TextInput
    source="code"
    label="mine.label.code"
    resettable
    variant="outlined"
    alwaysOn={true}
  />,
  <DateInput
    source="start_date"
    label="mine.label.start_date"
    variant="outlined"
    alwaysOn={true}
  />,
  <DateInput
    source="end_date"
    label="mine.label.end_date"
    variant="outlined"
    alwaysOn={true}
  />,
];

export default summaryFilterList;
