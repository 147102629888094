import { fetchUtils } from "react-admin";

const apiUrl = process.env.REACT_APP_API_URL + "/api/admin/v1/auths"; // "http://127.0.0.1:3300";
const tokenName = process.env.REACT_APP_TOKEN_NAME;
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem(tokenName);
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const url = `${apiUrl}/login`;
    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    }).then(({ headers, json }) => {
      localStorage.setItem(tokenName, json.data);
      return Promise.resolve();
    });
  },
  // called when the user clicks on the logout button
  logout: () => {
    if (localStorage[tokenName]) {
      const url = `${apiUrl}/logout`;
      return httpClient(url, {
        method: "POST",
        body: "{}",
      }).then(({ headers, json }) => {
        localStorage.removeItem(tokenName);
        return Promise.resolve();
      });
    }
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.removeItem(tokenName);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem(tokenName)
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const rolesUrl =
      process.env.REACT_APP_API_URL + "/api/admin/v1/user-access/roles";
    return httpClient(rolesUrl, {
      method: "GET",
    })
      .then(({ headers, json }) => {
        // localStorage.setItem(tokenName, json.data);
        if (json.roles && json.roles.length) {
          return Promise.resolve(json.roles);
        } else {
          return Promise.reject();
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  getIdentity: () => {
    // try {
    //   const { id, fullName, avatar } = JSON.parse(localStorage.getItem("auth"));
    //   return Promise.resolve({ id, fullName, avatar });
    // } catch (error) {
    //   return Promise.reject(error);
    // }
    const rolesUrl =
      process.env.REACT_APP_API_URL + "/api/admin/v1/auths/identity";
    return httpClient(rolesUrl, {
      method: "GET",
    })
      .then(({ headers, json }) => {
        if (json.id) {
          const { id, full_name: fullName, avatar } = json;
          return Promise.resolve({ id, fullName, avatar });
        } else {
          return Promise.reject();
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
};

export default authProvider;
