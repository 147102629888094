import {
  maxLength,
  minLength,
  required,
  TextInput,
  useDataProvider,
} from "react-admin";
import { useFormContext } from "react-hook-form";

const validateName = [required()];

export default function DisabledInput(props) {
  const { setValue } = useFormContext();

  const dataProvider = useDataProvider();

  const onCodeBlurHandler = async (e) => {
    const code = e.target.value;
    if (code.length !== 13) return;
    try {
      const { size, motif, density, color, quality } =
        await dataProvider.getListNoParams(
          "products/code-detail?code=" + encodeURIComponent(code)
        );
      setValue(
        "name",
        motif.name + " " + density.name + " " + color.name + " " + quality
      );
      // setValue("brand", brand.name);
      setValue("size", size.name);
      // setValue("motif", motif.name);
      // setValue("density", density.name);
      // setValue("color", color.name);
      // setValue("grade", grade.name);
    } catch (error) {
      setValue("name", "");
      setValue("size", "");
    }
  };

  return (
    <>
      <TextInput
        variant="outlined"
        source="name"
        fullWidth
        validate={validateName}
        label="mine.label.name"
      />

      <TextInput
        variant="outlined"
        source="color"
        fullWidth
        label="mine.label.color"
        //onBlur={onCodeBlurHandler}
      />

      <TextInput
        variant="outlined"
        source="size"
        fullWidth
        label="mine.label.size"
      />
    </>
  );
}
