import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  usePermissions,
  useTheme,
} from "react-admin";
import ProductAddDialog from "../../components/products/create/ProductAddDialog";
import ProductEditDialog from "../../components/products/edit/ProductEditDialog";
import productFilterList, {
  administratorProductFilterList,
} from "../../components/products/filter/productFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";
// import ProductExpandList from "../../components/products/expand/ProductExpandList";

const ProductList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        sort={{ field: "products.created_at", order: "ASC" }}
        actions={<TableListActions createHandler={handleClickOpenAdd} />}
        filters={
          permissions && !permissions.includes("admin_depo")
            ? administratorProductFilterList
            : productFilterList
        }
        // queryOptions={{ meta: { sort: ["products.created_at ASC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable
        // expand={<ProductExpandList />}
        // sx={{
        //   "& .RaDatagrid-expandedPanel > td": {
        //     p: 0,
        //     bgcolor: theme === "light" ? "#E7EBF0" : "grey.800",
        //   },
        // }}
        >
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField source="company.alias" label="Depo" />
          ) : null}
          <TextField source="name" label="mine.label.name" />
          <TextField source="color" label="mine.label.color" emptyText="-" />
          <TextField source="size" label="mine.label.size" emptyText="-" />
          {/* <TextField source="location" label="mine.label.location" /> */}
          <NumberField source="total_in" label="resources.in.name" />
          <NumberField source="total_out" label="resources.out.name" />
          <NumberField source="total_adj" label="mine.label.correction" />
          <NumberField source="inventory" label="mine.label.inventory" />
          <WrapperField label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <ProductAddDialog open={openAdd} setOpen={setOpenAdd} />
      <ProductEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default ProductList;
