import { useMemo, useState } from "react";
import {
  List,
  NumberField,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  BooleanField,
  usePermissions,
} from "react-admin";
import CustomerAddDialog from "../../components/customers/create/CustomerAddDialog";
import CustomerEditDialog from "../../components/customers/edit/CustomerEditDialog";
import customerFilterList, {
  administratorCustomerFilterList,
} from "../../components/customers/filter/customerFilterList";
import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";

const CustomerList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={<TableListActions createHandler={handleClickOpenAdd} />}
        filters={
          permissions && !permissions.includes("admin_depo")
            ? administratorCustomerFilterList
            : customerFilterList
        }
        queryOptions={{ meta: { sort: ["company_id ASC", "created_at DESC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable>
          <TextField source="name" label="mine.tbl_header.name" />
          <TextField source="code" label="mine.tbl_header.code" />
          <TextField source="pic" />
          <TextField source="phone" />
          <TextField source="address" />
          <TextField
            label="resources.sales_area.name"
            source="sales_area.name"
          />
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField label="Depo" source="company.alias" />
          ) : null}
          {/* <BooleanField source="active" label="mine.label.active" /> */}
          <WrapperField label="mine.tbl_header.actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <CustomerAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CustomerEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default CustomerList;
