import { useMemo, useState } from "react";
import {
  List,
  TextField,
  // WrapperField,
  DatagridConfigurable,
  // WithRecord,
  DateField,
  ReferenceField,
  NumberField,
  ArrayField,
  SingleFieldList,
  FunctionField,
  usePermissions,
} from "react-admin";
import CorrectionAddDialog from "../../components/corrections/create/CorrectionAddDialog";
import CorrectionEditDialog from "../../components/corrections/edit/CorrectionEditDialog";
import correctionFilterList from "../../components/corrections/filter/correctionFilterList";
// import EditBtn from "../../components/editBtn/EditBtn";
import TableListActions from "../../components/tableListActions/TableListActions";
import CompleteWithCustomExportActions from "../../components/tableListActions/CompleteWithCustomExportActions";

const CorrectionList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const { permissions } = usePermissions();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  // const handleClickOpenEdit = (recId) => () => {
  //   setRecordId(recId);
  //   setOpenEdit(true);
  // };
  const list = useMemo(
    () => (
      <List
        title="Koreksi Stok"
        empty={false}
        actions={
          <CompleteWithCustomExportActions createHandler={handleClickOpenAdd} />
        }
        filters={correctionFilterList(permissions)}
        filter={{ type: "adj" }}
        sort={{ field: "created_at", order: "DESC" }}
        // filterDefaultValues={{ type: "adj" }}
        // queryOptions={{ meta: { sort: ["id DESC"] } }} // overide default sort
        sx={{
          "& .RaList-main > .MuiToolbar-root": {
            flexDirection: "column",
            alignItems: "baseline",
          },
        }}
      >
        <DatagridConfigurable>
          {permissions && !permissions.includes("admin_depo") ? (
            <TextField source="company.alias" label="Depo" />
          ) : null}
          <FunctionField
            label="mine.label.code"
            component="p"
            render={(record) => {
              if (record.correction_in.code) {
                return record.correction_in.code;
              } else if (record.correction_out.code) {
                return record.correction_out.code;
              }
            }}
          />
          <ArrayField source="product_adjs" label="mine.label.product">
            <SingleFieldList linkType={false} sx={{ flexDirection: "column" }}>
              <TextField source="product.name" component="p" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="product_adjs" label="mine.label.amount">
            <SingleFieldList linkType={false} sx={{ flexDirection: "column" }}>
              <NumberField source="mutation" component="p" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="note" label="mine.label.note" />
          <DateField
            source="created_at"
            label="mine.tbl_header.created_at"
            showTime
          />
          <ReferenceField
            label="mine.tbl_header.created_by"
            source="created_by"
            reference="users/some"
          >
            <TextField source="username" />
          </ReferenceField>
          {/* <WrapperField label="Actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField> */}
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      {openAdd ? (
        <CorrectionAddDialog open={openAdd} setOpen={setOpenAdd} />
      ) : null}
      {openEdit ? (
        <CorrectionEditDialog
          open={openEdit}
          setOpen={setOpenEdit}
          recordId={recordId}
        />
      ) : null}
    </>
  );
};

export default CorrectionList;
