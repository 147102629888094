import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import { DateField, RecordContextProvider, useGetIdentity } from "react-admin";
import splitArrayToChunks from "../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  const { identity, isLoading: identityLoading } = useGetIdentity();

  let totalMutation = 0;
  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box
        ref={ref}
        sx={{
          fontSize: "12px",
          py: 2,
          px: 2,
          display: "flex",
          flexDirection: "column",
          height: "calc(100vh - 35px)",
        }}
      >
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in landscape;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.product_outs, 3).map((arr, i, ori) => {
          return (
            <Fragment key={i}>
              <Box sx={i > 0 ? { mt: 2 } : {}}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      mb: 1,
                      fontFamily: "'Source Code Pro', monospace",
                    }}
                  >
                    {props.record.company.alias}
                  </Typography>
                  <div style={{ fontSize: "10px" }}>
                    {i + 1}/{ori.length}
                  </div>
                </div>
                <Box
                  component="table"
                  className={classes.headerSj}
                  sx={{
                    "& th.no-wrap": {
                      whiteSpace: "nowrap",
                    },
                    "& th": {
                      verticalAlign: "top",
                    },
                  }}
                >
                  <thead>
                    <tr>
                      <th className="no-wrap">Kepada Yth</th>
                      <th>:</th>
                      <th>{props.record.delivery_note.customer.name}</th>
                      <th style={{ width: "20%" }}></th>
                      <th className="no-wrap">No. Surat Jalan</th>
                      <th>:</th>
                      <th className="no-wrap">
                        {props.record.delivery_note.code}
                      </th>
                    </tr>
                    <tr>
                      <th className="no-wrap">Telp/Fax</th>
                      <th>:</th>
                      <th className="no-wrap">
                        {props.record.delivery_note.customer.phone}
                        {props.record.delivery_note.customer.fax
                          ? " / " + props.record.delivery_note.customer.fax
                          : ""}
                      </th>
                      <th style={{ width: "20%" }}></th>
                      <th className="no-wrap">Tgl. Pengiriman</th>
                      <th>:</th>
                      <th className="no-wrap">
                        <RecordContextProvider value={props.record}>
                          <DateField
                            source="send_date"
                            sx={{
                              fontWeight: "inherit",
                              fontSize: "12px",
                            }}
                          ></DateField>
                        </RecordContextProvider>
                      </th>
                    </tr>
                    <tr>
                      <th className="no-wrap">Alamat</th>
                      <th>:</th>
                      <th>
                        <span style={{ textTransform: "uppercase" }}>
                          {props.record.delivery_note.customer.address}
                        </span>
                      </th>
                      <th style={{ width: "20%" }}></th>
                      <th className="no-wrap">No. Customer</th>
                      <th>:</th>
                      <th className="no-wrap">
                        {props.record.delivery_note.customer.code}
                      </th>
                    </tr>
                  </thead>
                </Box>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    mt: 1,
                    mb: 1,
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  Delivery Order
                </Typography>
              </Box>
              <Box sx={{ height: "100%" }}>
                <Box
                  component="table"
                  className={classes.tableSj}
                  sx={{
                    width: "100%",
                    height: "100%",
                    "& th.no-wrap": {
                      whiteSpace: "nowrap",
                    },
                    "& th": {
                      verticalAlign: "top",
                    },
                  }}
                >
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Warna</th>
                      <th>Ukuran</th>
                      <th style={{ textAlign: "right" }}>Banyak</th>
                      <th style={{ textAlign: "center" }}>Satuan</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {arr.map((obj, idx) => {
                      totalMutation += obj.mutation * -1;
                      return (
                        <tr key={obj.product.id}>
                          <td>
                            <p
                              style={{
                                margin: 0,
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {obj.product.name}
                            </p>
                          </td>
                          <td className={classes.noWrap}>
                            {obj.product.color}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {obj.product.size}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {obj.mutation * -1}
                          </td>
                          <td style={{ textAlign: "center" }}>{"Unit"}</td>
                          {idx === 0 && (
                            <td
                              rowSpan={props.record.product_outs.length}
                              style={{ width: "180px" }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  display: "-webkit-box",
                                  WebkitLineClamp: 7,
                                  WebkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {props.record.note}
                              </p>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                        }}
                      >
                        Total:
                      </td>
                      <td
                        style={{
                          textAlign: "right",
                          verticalAlign: "baseline",
                          fontWeight: "bold",
                        }}
                      >
                        {totalMutation}
                      </td>
                      <td colSpan={2}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "10px",
                          }}
                        >
                          <div>Catatan</div>
                          <div>Lembar ke:</div>
                          <div>
                            <Typography variant="inherit">
                              1. Warna Putih untuk Perusahaan
                            </Typography>
                            <Typography variant="inherit">
                              2. Warna Merah untuk Penerima Barang
                            </Typography>
                            <Typography variant="inherit">
                              3. Warna Kuning untuk Angkutan
                            </Typography>
                            <Typography variant="inherit">
                              4. Warna Biru untuk Gudang
                            </Typography>
                            <Typography variant="inherit">
                              5. Warna Hijau untuk Accounting
                            </Typography>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Box>
              </Box>
              <Box
                className={classes.code}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  // flex: 1,
                  // height: "100%",
                  // alignItems: "flex-end"
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="inherit" sx={{ mb: 5 }}>
                    Dicetak Oleh
                  </Typography>
                  <Typography>( .............. )</Typography>
                  <Typography variant="inherit">ADMIN</Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="inherit" sx={{ mb: 5 }}>
                    Diketahui
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <Typography>( .............. )</Typography>
                      <Typography variant="inherit">AKUNTING</Typography>
                    </Box>
                    <Box>
                      <Typography>( .............. )</Typography>
                      <Typography variant="inherit">K. DEPO</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="inherit" sx={{ mb: 5 }}>
                    Pelaksana
                  </Typography>
                  <Box>
                    <Typography>( .............. )</Typography>
                    <Typography variant="inherit">K. GUDANG</Typography>
                  </Box>
                </Box>
              </Box>
              <Typography
                component="p"
                variant="caption"
                sx={{
                  textAlign: "right",
                  mt: 2,
                  fontSize: "10px",
                  fontFamily: "'Source Code Pro', monospace",
                }}
              >
                Dicetak oleh:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {identityLoading ? "..." : identity.fullName.toLowerCase()}
                </span>{" "}
                <span className="current-date-time" />
              </Typography>
              <div className="page-break" />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
});
export default PrintContent;
