import { useMemo, useState } from "react";
import {
  DateField,
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  ExportButton,
  Button,
  FunctionField,
  WithRecord,
} from "react-admin";
import SalesAreaAddDialog from "../../components/salesAreas/create/SalesAreaAddDialog";
import AddIcon from "@mui/icons-material/Add";
import SalesAreaEditDialog from "../../components/salesAreas/edit/SalesAreaEditDialog";
import EditBtn from "../../components/editBtn/EditBtn";
import salesAreaFilterList from "../../components/salesAreas/filter/salesAreaFilterList";

const PostListActions = ({ createHandler }) => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <Button
      startIcon={<AddIcon />}
      label="Create"
      sx={{ width: "fit-content" }}
      onClick={createHandler}
    />
    <ExportButton />
  </TopToolbar>
);

const SalesAreaList = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        empty={false}
        actions={<PostListActions createHandler={handleClickOpenAdd} />}
        filters={salesAreaFilterList}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable>
          <TextField label="Area" source="name" />
          <TextField source="code" />
          <TextField label="Company" source="company.alias" />
          <DateField source="created_at" showTime />
          <WrapperField label="Actions">
            <WithRecord
              label="author"
              render={(record) => {
                return <EditBtn editHandler={handleClickOpenEdit(record.id)} />;
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    []
  );
  return (
    <>
      {list}
      <SalesAreaAddDialog open={openAdd} setOpen={setOpenAdd} />
      <SalesAreaEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
    </>
  );
};

export default SalesAreaList;
