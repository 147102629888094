import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useNotify,
  TextInput,
  useResourceContext,
  required,
  maxLength,
  useRefresh,
  useDataProvider,
  useCreate,
  Create,
  minLength,
  AutocompleteInput,
  BooleanInput,
  SimpleForm,
  usePermissions,
  useTranslate,
  number,
  ReferenceInput,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";
import Transition from "../../transition/Transition";
import CustomerAddressForm from "./components/CustomerAddressForm";

const validateCompany = [required(), minLength(36), maxLength(36)];
const validateSalesArea = [required(), minLength(36), maxLength(36)];
const validateName = [required(), maxLength(125)];
const validatePIC = [maxLength(50)];
const validatePhone = [required(), number()];
const validateAddress = [required()];

export default function CustomerAddDialog({ open, setOpen }) {
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const { isLoading: isLoadingPermissions, permissions } = usePermissions();

  // GET COMPANY LISTS
  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companyError,
  } = useQuery(["companies/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("companies/select")
  );
  // GET COMPANY LISTS

  const save = useCallback(
    async (values) => {
      try {
        await create(
          resource,
          { data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.created", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted={false}
      TransitionComponent={Transition}
    >
      <Create
        title={" "}
        sx={{
          "& .RaCreate-main": { mt: 0 },
          "& .RaCreate-card": { boxShadow: "none" },
        }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>{t("mine.dialog.create_customer")}</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            {!isLoadingPermissions & permissions.includes("administrator") ? (
              <AutocompleteInput
                variant="outlined"
                source="company_id"
                label="Depo"
                isRequired
                choices={isLoadingCompanies ? [] : companies}
                fullWidth
                validate={validateCompany}
              />
            ) : null}

            <ReferenceInput
              alwaysOn
              source="sales_area_id"
              reference="sales-areas"
              // label="Depo"
              sort={{ field: "sales_areas.code", order: "ASC" }}
              resettable
            >
              <AutocompleteInput
                optionText="name"
                variant="outlined"
                label="resources.sales_area.name"
                validate={validateSalesArea}
              />
            </ReferenceInput>

            <TextInput
              variant="outlined"
              source="name"
              label="mine.label.name"
              fullWidth
              isRequired
              validate={validateName}
            />

            <TextInput
              variant="outlined"
              label="Person in charge"
              source="pic"
              fullWidth
              validate={validatePIC}
            />

            <TextInput
              variant="outlined"
              label="mine.label.phone"
              source="phone"
              fullWidth
              validate={validatePhone}
            />

            <TextInput
              variant="outlined"
              source="address"
              label="mine.label.address"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateAddress}
            />

            <TextInput
              variant="outlined"
              source="note"
              label="mine.label.note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
            />

            <BooleanInput
              source="active"
              defaultValue={true}
              sx={{ display: "none" }}
            />

            {/* <CustomerAddressForm /> */}
          </DialogContent>
        </SimpleForm>
      </Create>
    </Dialog>
  );
}
