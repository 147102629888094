import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery } from "react-query";
import {
  useUpdate,
  useNotify,
  SimpleForm,
  TextInput,
  NumberInput,
  useResourceContext,
  required,
  number,
  choices,
  useRefresh,
  useDataProvider,
  Edit,
  AutocompleteInput,
  ReferenceInput,
  minValue,
  RadioButtonGroupInput,
  minLength,
  maxLength,
} from "react-admin";
import SaveDeleteBtns from "../../saveDeleteBtns/SaveDeleteBtn";

const validateProduct = [required(), minLength(36), maxLength(36)];
const validateMutation = [required(), number(), minValue(1)];
const validateType = [
  required(),
  choices(["adj"], "Please choose one of the values"),
];
const validateCategory = [required()];
const validateNote = [required()];

const filterToQuery = (searchText) => ({ name: searchText });

export default function CorrectionEditDialog({ open, setOpen, recordId }) {
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const resource = useResourceContext(); // cuman nama resource
  const dataProvider = useDataProvider();

  // GET COMPANY LISTS
  const {
    data: products,
    isLoading: isLoadingProducts,
    error: productError,
  } = useQuery(["products/select", "getListNoParams"], () =>
    dataProvider.getListNoParams("products/select")
  );
  // GET COMPANY LISTS

  const save = useCallback(
    async (values) => {
      try {
        values.mutation = +(values.category + values.mutation);
        await update(
          resource,
          { id: values.id, data: values },
          {
            returnPromise: true,
            onSuccess: (data) => {
              notify("ra.notification.updated", {
                messageArgs: { smart_count: 1 },
              });
              setOpen(false);
              refresh();
            },
          }
        );
      } catch (error) {
        if (error.body.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} keepMounted={false}>
      <Edit
        title={" "}
        id={recordId}
        actions={false}
        sx={{ "& .RaEdit-main": { mt: 0 } }}
      >
        <SimpleForm
          warnWhenUnsavedChanges
          onSubmit={save}
          toolbar={<SaveDeleteBtns />}
          mode="onBlur"
          reValidateMode="onBlur"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <DialogTitle>Edit Correction</DialogTitle>
            <IconButton onClick={handleClose} sx={{ mr: "11px" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <TextInput disabled source="id" sx={{ display: "none" }} />

            <ReferenceInput
              label="Product"
              source="product_id"
              reference="products"
              // perPage={10}
            >
              <AutocompleteInput
                variant="outlined"
                isRequired
                fullWidth
                validate={validateProduct}
                filterToQuery={filterToQuery}
                optionText="code"
              />
            </ReferenceInput>

            <TextInput
              disabled
              source="type"
              defaultValue="adj"
              sx={{ display: "none" }}
              validate={validateType}
            />

            <RadioButtonGroupInput
              source="category"
              choices={[
                { id: "-", name: "Kurangi" },
                { id: "+", name: "Tambah" },
              ]}
              validate={validateCategory}
              row={false}
            />
            {/* <SelectInput
              variant="outlined"
              source="type"
              choices={[
                { id: "in", name: "Masuk" },
                { id: "out", name: "Keluar" },
                { id: "adj", name: "Penyesuaian" },
              ]}
              fullWidth
              isRequired
              validate={validateType}
            /> */}

            <NumberInput
              variant="outlined"
              source="mutation"
              label="Amount"
              fullWidth
              isRequired
              validate={validateMutation}
            />

            <TextInput
              variant="outlined"
              source="note"
              fullWidth
              multiline
              minRows={3}
              maxRows={3}
              validate={validateNote}
            />
          </DialogContent>
        </SimpleForm>
      </Edit>
    </Dialog>
  );
}
