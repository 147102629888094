import Grid from "@mui/material/Grid";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  minValue,
  number,
  NumberInput,
  ReferenceInput,
  required,
} from "react-admin";
import ProductCodeDetail from "./ProductCodeDetail";
import {
  OptionRenderer,
  orFilterToQuery,
  orInputText,
  orMatchSuggestion,
} from "../../../common/OptionRenderer";

const greaterThanStock = (value, values, all) => {
  let idx = +all.source.split(".")[1];
  let thisChild = values.product_outs[idx];
  if (value > thisChild.stock) {
    return "Stok saat ini kurang dari " + value;
  }
  return undefined;
};

const validateMutation = [required(), number(), minValue(1), greaterThanStock];
const validateMutationEdit = [required(), number(), minValue(1)];
const validateProduct = [required(), minLength(36), maxLength(36)];

const ArrayInputChild = ({
  productIdSource,
  mutationSource,
  nameSource,
  stockSource,
  isEdit,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item:not(:first-of-type)": { pt: 0 },
        mt: 0,
      }}
    >
      <Grid item xs={12} paddingTop={0}>
        <ReferenceInput
          source={productIdSource}
          reference="products"
          // perPage={10}
        >
          <AutocompleteInput
            label="mine.label.name"
            variant="outlined"
            fullWidth
            validate={validateProduct}
            // optionText="name"
            optionText={<OptionRenderer />}
            inputText={orInputText}
            matchSuggestion={orMatchSuggestion}
            filterToQuery={orFilterToQuery}
          />
        </ReferenceInput>
      </Grid>
      <ProductCodeDetail
        name={nameSource}
        stock={stockSource}
        dependOn={productIdSource}
        resource="products"
        queryName="id"
      />
      <Grid item xs={12} md={6} paddingTop={0}>
        <NumberInput
          variant="outlined"
          source={mutationSource}
          label="mine.label.total"
          fullWidth
          validate={isEdit ? validateMutationEdit : validateMutation}
        />
      </Grid>
    </Grid>
  );
};

const Inputs = ({
  formData, // The whole form data
  scopedFormData, // The data for this item of the ArrayInput
  getSource, // A function to get the valid source inside an ArrayInput
  isEdit,
}) => {
  return (
    <ArrayInputChild
      {...scopedFormData}
      isEdit={isEdit}
      productIdSource={getSource("product_id")}
      nameSource={getSource("name")}
      stockSource={getSource("stock")}
      mutationSource={getSource("mutation")}
    />
  );
};
export default Inputs;
