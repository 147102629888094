import { useRecordContext } from "react-admin";

export const OptionRenderer = () => {
  const record = useRecordContext();
  return (
    <span>
      {record.name} {record.color ? " - " + record.color : ""}{" "}
      {record.size ? " - " + record.size : ""}
    </span>
  );
};
export const orInputText = (choice) =>
  `${choice.name} ${choice.color ? "- " + choice.color : ""} ${
    choice.size ? "- " + choice.size : ""
  }`;
export const orMatchSuggestion = (filter, choice) => {
  if (choice.name === undefined) {
    return false;
  }
  return (
    choice.name.toLowerCase().includes(filter.toLowerCase()) ||
    choice.color.toLowerCase().includes(filter.toLowerCase()) ||
    choice.size.toLowerCase().includes(filter.toLowerCase())
  );
};
export const orFilterToQuery = (searchText) => ({ all: searchText });
