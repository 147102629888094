import { ArrayInput, FormDataConsumer, SimpleFormIterator } from "react-admin";
import Inputs from "./Inputs";

export default function ProductInForm() {
  return (
    <ArrayInput
      source="product_ins"
      defaultValue={[
        {
          product_id: undefined,
          mutation: undefined,
        },
      ]}
    >
      <SimpleFormIterator
        getItemLabel={(index) => `#${index + 1}`}
        // disableAdd
        // disableClear
        // disableRemove
        // disableReordering
        fullWidth
        sx={{
          "& .RaSimpleFormIterator-action": {
            display: "flex",
            flexDirection: "column",
          },
          "& .RaSimpleFormIterator-action > .button-reorder": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <FormDataConsumer>{Inputs}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
}
