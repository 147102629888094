import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  TextInput,
} from "react-admin";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const goodsOutFilterList = (permissions) => {
  const defaultFilter = [
    <TextInput
      source="all"
      label="mine.filter.search_all"
      alwaysOn
      resettable
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />,
    <TextInput
      source="delivery_note"
      alwaysOn
      variant="outlined"
      label="Delivery order"
    />,
    <ReferenceInput
      alwaysOn
      source="customer_id"
      reference="customers"
      // label="Depo"
      sort={{ field: "customers.id", order: "ASC" }}
      resettable
    >
      <AutocompleteInput
        optionText="name"
        variant="outlined"
        label="mine.label.customer"
      />
    </ReferenceInput>,
    <DateInput
      source="send_date"
      alwaysOn
      variant="outlined"
      label="mine.label.send_date"
    />,
    <TextInput
      alwaysOn
      source="note"
      variant="outlined"
      label="mine.label.note"
    />,
    <TextInput
      source="product"
      alwaysOn
      variant="outlined"
      label="mine.label.product"
    />,
    <DateInput
      source="start_date"
      label="mine.label.start_date"
      variant="outlined"
      alwaysOn={true}
    />,
    <DateInput
      source="end_date"
      label="mine.label.end_date"
      variant="outlined"
      alwaysOn={true}
    />,
  ];
  if (permissions && !permissions.includes("admin_depo")) {
    defaultFilter.splice(
      1,
      0,
      <ReferenceInput
        alwaysOn
        source="company_id"
        reference="companies"
        // label="Depo"
        sort={{ field: "companies.alias", order: "ASC" }}
        resettable
      >
        <AutocompleteInput optionText="alias" variant="outlined" label="Depo" />
      </ReferenceInput>
    );
  }
  return defaultFilter;
};
export default goodsOutFilterList;
